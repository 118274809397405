@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.styles_container__fyw6Z {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 555;
  -webkit-backdrop-filter: saturate(180%);
          backdrop-filter: saturate(180%);
  background-color: hsla(0, 0%, 0%, .2);
}

.styles_container__fyw6Z.active {
  display: block;
}

.styles_container__3BuoN {
  padding-top: var(--box-main-padding);
  padding-bottom: var(--box-main-padding);
}

@media (min-width: 1024px) {
  .styles_container__3BuoN {
    max-width: calc(100% - 280px);
  }
}

.styles_container__1B-iR {
  color: var(--color-white);
  background-color: var(--color-background-lighten-weak);
}

.styles_container__1B-iR .main-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.styles_wrap__2MO7O {
  flex-shrink: 0;
  width: 100%;
  max-width: 280px;
}

.styles_responsive__1WHz0 {
  margin-bottom: 20px;
  padding-top: var(--box-main-padding);
}

.styles_close__button__380XW {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: var(--box-spacing);
}

.styles_container__3gn9N {
  flex-shrink: 0;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0px;
  visibility: hidden;
  width: 100%;
  z-index: 777;
  max-width: 280px;
  margin-left: -280px;
  box-shadow: 2px 0 2px 0 hsla(0, 0%, 0%, .1);
  background-color: var(--color-background);
  transition: margin-left 300ms, visibility 0ms linear 300ms;
}

.styles_container__3gn9N.opened {
  margin-left: 0px;
  visibility: visible;
  transition: margin-left 300ms linear;
}

.styles_content__3ciVK {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--box-main-padding);
  padding-left: calc(var(--box-spacing) - 8px);
}

.styles_content__3ciVK > div + div {
  margin-top: 25px;
}

.styles_content__3ciVK > div > h4 {
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 8px;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: var(--color-black-weak);
}

.styles_content__3ciVK > div > section {
  display: flex;
  flex-direction: column;
}

.styles_content__3ciVK > div > section a + a {
  margin-top: 4px;
}

.styles_content__3ciVK > div > section a {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
}

.styles_content__3ciVK > div > section a.active,
.styles_content__3ciVK > div > section a:hover {
  color: var(--color-blue);
  background-color: var(--color-grey-weak);
}

.styles_content__3ciVK > div > section a.active {
  color: var(--color-grey);
}

@media (min-width: 1024px) {
  .styles_responsive__1WHz0, .styles_close__button__380XW {
    display: none !important;
  }

  .styles_container__3gn9N {
    margin-left: 0px;
    position: sticky;
    visibility: visible;
  }

  .styles_content__3ciVK {
    padding-top: var(--box-main-padding);
  }
}

/* Scroll */

.styles_container__3gn9N::-webkit-scrollbar {
  width: 5px;
}

.styles_container__3gn9N::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 0%, .1);
}

.styles_container__3gn9N::-webkit-scrollbar-thumb {
  background-color: var(--color-papaya);
}

.styles_container__3gn9N::-webkit-scrollbar-track:hover {
  background-color: hsla(0, 0%, 0%, .2);
}

.styles_container__3gn9N:hover::-webkit-scrollbar {
  width: 8px;
}

.styles_content__36d-_ {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media (min-width: 1024px) {
  .styles_content__36d-_ {
    flex-direction: row;
  }
}

:root {
  --box-spacing: 20px;
  --box-main-padding: 30px;
  --box-max-width: 1024px;
  --main-transparency: 10%;
  --main-border-radius: 3px;
  --main-bigger-border-radius: 6px;
  --main-box-shadow: 0 0 4px 0 hsla(0, 0%, 0%, .12);
  --main-bottom-box-shadow: 0 2px 4px 0 hsla(0, 15%, 5%, .12);
  /* Colors */
  --color-background: hsl(0, 0%, 100%);
  --color-background-dark: hsl(252, 16%, 6%);
  --color-background-lighten: hsl(246, 14%, 14%);
  --color-background-lighten-weak: hsl(246, 15%, 13%);
  --color-black: hsl(0, 0%, 0%);
  --color-black-weak: hsl(0, 0%, 20%);
  --color-grey: hsl(0, 0%, 63%);
  --color-grey-weak: hsl(240, 44%, 96%);
  --color-white: hsl(0, 0%, 100%);
  --color-white-strong: hsl(0, 0%, 88%);
  --color-blue: hsl(202, 98%, 50%);
  --color-papaya: hsl(24, 80%, 75%);
}

@media (min-width: 1536px) {
  :root {
    --box-spacing: 60px;
  }
}

@media (min-width: 1280px) {
  :root {
    --box-spacing: 50px;
  }
}

@media (min-width: 1024px) {
  :root {
    --box-spacing: 40px;
  }
}

@media (min-width: 768px) {
  :root {
    --box-spacing: 35px;
  }
}

@media (min-width: 640px) {
  :root {
    --box-spacing: 30px;
  }
}

body {
  color: var(--color-white);
  color: var(--color-black);
  background-color: var(--color-background);
}

input,
button,
textarea {
  color: currentColor;
}

body,
input,
button,
textarea {
  font-family: unset, -apple-system, sans-serif;
}

#content {
  flex: 1 1;
}

#layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
}

.main-container-base {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: var(--box-spacing);
  padding-right: var(--box-spacing);
}

.main-container-loose {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: calc(var(--box-max-width) + (var(--box-spacing) * 2));
}

.main-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: calc(var(--box-max-width) + (var(--box-spacing) * 2));
  padding-left: var(--box-spacing);
  padding-right: var(--box-spacing);
}

.text-gradient {
  background-clip: text;
  background-size: 100%;
  background-color: var(--colors-white);
  -ms-background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -ms-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

p,
body,
input,
button,
textarea {
  font: 400 1.6rem 'Roboto', sans-serif;
}

.container{
  width: 100%
}

@media (min-width: 640px){
  .container{
    max-width: 640px
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px
  }
}

@media (min-width: 1536px){
  .container{
    max-width: 1536px
  }
}

.flex{
  display: flex
}

.table{
  display: table
}

.flex-wrap{
  flex-wrap: wrap
}

.items-center{
  align-items: center
}

.justify-between{
  justify-content: space-between
}

.my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.ml-3{
  margin-left: 0.75rem
}

.static{
  position: static
}

*{
  --tw-shadow: 0 0 hsla(0, 0%, 0%, 0)
}

*{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: hsl(0, 0%, 100%);
  --tw-ring-color: hsla(217, 91%, 60%, .5);
  --tw-ring-offset-shadow: 0 0 hsla(0, 0%, 0%, 0);
  --tw-ring-shadow: 0 0 hsla(0, 0%, 0%, 0)
}

.w-full{
  width: 100%
}

@-webkit-keyframes spin{
  to{
    transform: rotate(360deg)
  }
}

@keyframes spin{
  to{
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0
  }
}

@keyframes ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5
  }
}

@keyframes pulse{
  50%{
    opacity: .5
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce{
  0%, 100%{
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

@media (min-width: 640px){
}

@media (min-width: 768px){
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 1536px){
}

