.main-container-base {
  @apply w-full mx-auto;

  padding-left: var(--box-spacing);
  padding-right: var(--box-spacing);
}

.main-container-loose {
  @apply w-full mx-auto;

  max-width: calc(var(--box-max-width) + (var(--box-spacing) * 2));
}

.main-container {
  @apply w-full mx-auto;

  max-width: calc(var(--box-max-width) + (var(--box-spacing) * 2));

  padding-left: var(--box-spacing);
  padding-right: var(--box-spacing);
}

.text-gradient {
  background-clip: text;
  background-size: 100%;
  background-color: var(--colors-white);

  -ms-background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;

  -ms-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
